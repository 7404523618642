<template>
    <y-panel
        class="coupons-editor-limitation"
        :title="$t('coupons.editor.limitation.panel')"
    >
        <y-form-field
            v-model="limitation"
            type="radio"
            class="coupons-editor__radio"
            name="coupon.limitation.radio"
            :options="options"
            inline
        />

        <!-- Many -->
        <y-form-field
            v-if="limitation === 'many'"
            v-model="model.code_usage_limit"
            type="number"
            name="coupon.limitation.many"
            format="separated"
            validation="required"
        />

        <y-alert
            v-if="limitation === 'unlimited'"
            color="blue"
            :message="$t('coupons.editor.messages.limit')"
        />
    </y-panel>
</template>

<script>

    import { YFormField, YAlert } from '@deps';

    export default {
        name: 'CouponLimitationPanel',

        components: {
            YFormField,
            YAlert,
        },

        props: {
            settings: Object,
            value   : Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model     : this.value || {},
                limitation: null,

                options: {
                    once     : this.$t('coupons.editor.limitation.once'),
                    many     : this.$t('coupons.editor.limitation.many'),
                    unlimited: this.$t('coupons.editor.limitation.unlimited'),
                },

                watchLimit: false,
            };
        },


        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            limitation: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    if (this.watchLimit) {
                        if (this.limitation === 'once') {
                            this.$set(this.model, 'code_usage_limit', 1);
                        }
                        if (this.limitation === 'many') {
                            this.$set(this.model, 'code_usage_limit', '');
                        }
                        if (this.limitation === 'unlimited') {
                            this.$set(this.model, 'code_usage_limit', 0);
                        }
                    }
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetchData();
            setTimeout(() => {
                this.watchLimit = true;
            }, 1000);
        },



        methods: {
            /**
             * Fetch data
             */
            fetchData() {
                if (this.model) {
                    if (this.model.code_usage_limit === '1') {
                        this.limitation = 'once';
                    } else if (this.model.code_usage_limit === '0') {
                        this.limitation = 'unlimited';
                    } else if (this.model.code_usage_limit && this.model.code_usage_limit !== '0' && this.model.code_usage_limit !== '1') {
                        this.limitation = 'many';
                    }
                }
            },
        },
    };

</script>
