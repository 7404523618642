<template>
    <y-panel
        class="coupons-editor-group"
        :title="$t('coupons.editor.group.panel')"
    >
        <y-form-field
            v-model="users"
            type="radio"
            class="coupons-editor__radio"
            name="coupon.group.radio"
            :options="options"
            inline
        />

        <!-- User -->
        <template v-if="users === 'user'">
            <y-form-field
                v-model="tempUsers"
                type="select"
                name="coupon.group.search_user"
                :options="[]"
                :endpoint="userEndpoint"
                label-field="name_full"
                value-field="id"
                search="search"
                multi
                validation="required"
                @select="addUser($event)"
            />
            <div>
                <div
                    v-for="(user, index) in usersOptions"
                    :key="index"
                    class="coupons-editor-group__users label mr5"
                >
                    <div class="d-f justify-content-between align-items-center">
                        <span>{{ user.name_full || user.label }}</span>
                        <i
                            class="icon mr5 ml0 md-close"
                            @click="removeUser(user)"
                        />
                    </div>
                </div>
            </div>
        </template>

        <!-- Group -->
        <y-form-field
            v-else-if="users === 'role'"
            v-model="model.users.roles"
            type="select"
            name="coupon.group.role"
            :options="roles"
            label-field="label"
            value-field="value"
            multi
            validation="required"
        />

        <y-alert
            v-if="users === 'all'"
            color="blue"
            :message="$t('coupons.editor.messages.user')"
        />
    </y-panel>
</template>

<script>

    import { YFormField, YAlert } from '@deps';

    export default {
        name: 'CouponGroupPanel',

        components: {
            YFormField,
            YAlert,
        },

        props: {
            settings: Object,
            value   : Object,
            roles   : Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
                users: null,

                options: {
                    all : this.$t('coupons.editor.group.all'),
                    user: this.$t('coupons.editor.group.user'),
                    role: this.$t('coupons.editor.group.role'),
                },

                userEndpoint: {
                    name: 'coupons-user-search',
                },
                tempUsers   : null,
                usersOptions: [],
                usersId     : [],

                watchUsers: false,
            };
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            users: {
                /**
                 * Set model to all if all selected
                 */
                handler() {
                    if (this.watchUsers) {
                        this.$set(this.model, 'users', {});
                        if (this.users === 'all') {
                            this.$set(this.model.users, 'all', 1);
                        }
                    }
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetchData();
            if (this.value.users && this.value.users.user_ids && this.value.users.user_ids.length) {
                Object.keys(this.value.users.user_ids).forEach((key) => {
                    if (this.value.users.user_ids[key].value) {
                        this.usersOptions.push({
                            id       : this.value.users.user_ids[key].value,
                            name_full: this.value.users.user_ids[key].label,
                        });
                        this.usersId.push(this.value.users.user_ids[key].value);
                    }
                });
                this.$set(this.model.users, 'user_ids', this.usersId);
            }
            setTimeout(() => {
                this.watchUsers = true;
            }, 1000);
        },

        methods: {
            /**
             * Fetch data
             */
            fetchData() {
                if (this.model) {
                    Object.keys(this.model.users).forEach((key) => {
                        if (key === 'all') {
                            this.users = 'all';
                        } else if (key === 'roles') {
                            this.users = 'role';
                        } else if (key === 'user_ids') {
                            this.users = 'user';
                        }
                    });
                }
            },

            /**
             * Add User
             *
             * @param item
             * @param param
             */
            addUser(item, param) {
                if (item) {
                    const id = param === 'value' ? item.value : item.id;
                    const duplicate = !!this.usersId.find((i) => i === id);
                    if (!duplicate) {
                        this.usersOptions.push(item);
                    }
                    if (!this.usersId.includes(id)) {
                        this.usersId.push(id);
                        this.$set(this.model.users, 'user_ids', this.usersId);
                    }
                }
            },

            /**
             * Remove User
             *
             * @param item
             */
            removeUser(item) {
                this.usersOptions.splice(this.usersOptions.findIndex((i) => i.id === item.id), 1);
                this.usersId.splice(this.userId.indexOf(item.id), 1);
                this.$set(this.model.users, 'user_ids', this.usersId);
            },
        },
    };

</script>
