<template>
    <y-panel
        class="coupons-editor-amount"
        :title="$t('coupons.editor.amount.panel')"
    >
        <y-form-field
            v-model="model._amount"
            type="radio"
            class="coupons-editor__radio"
            name="coupon.amount.radio"
            :options="options"
            inline
        />

        <!-- Amount Fee -->
        <y-form-field
            v-if="model._amount === 'amount_fee'"
            v-model="model.applicable_amount"
            type="number"
            name="coupon.amount.fee_number"
            format="separated"
            validation="required"
        />

        <!-- Amount Percent -->
        <div
            v-if="model._amount === 'amount_percent'"
            class="row"
        >
            <div class="col">
                <y-form-field
                    v-model="model.applicable_percent"
                    type="number"
                    name="coupon.amount.percent_number"
                    format="separated"
                    validation="required"
                />
            </div>
            <div class="col">
                <y-form-field
                    v-model="model.applicable_amount"
                    type="number"
                    name="coupon.amount.percent_max"
                    format="separated"
                    validation="required"
                />
            </div>
        </div>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    export default {
        name: 'CouponAmountPanel',

        components: {
            YFormField,
        },

        props: {
            settings: Object,
            value   : Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                options: {
                    amount_percent: this.$t('coupons.editor.amount.percent'),
                    amount_fee    : this.$t('coupons.editor.amount.fee'),
                },
            };
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$set(this.model, '_amount', null);
            this.fetchData();
        },

        methods: {
            /**
             * Fetch data
             */
            fetchData() {
                if (this.value.applicable_percent) {
                    this.$set(this.model, '_amount', 'amount_percent');
                } else if (this.value.applicable_amount && !this.value.applicable_percent) {
                    this.$set(this.model, '_amount', 'amount_fee');
                } else if (!this.value.applicable_amount && !this.value.applicable_percent) {
                    this.$set(this.model, '_amount', 'amount_percent');
                }
            },
        },
    };

</script>
