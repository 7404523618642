<template>
    <y-panel class="coupons-editor-base">
        <template v-if="locales && locales.length">
            <div
                v-for="locale in locales"
                :key="`${locale}-base-${locale}`"
                class="localed-inputs"
            >
                <div class="locale-title">
                    {{ $t(`general.locales.${locale}`) }}
                </div>
                <y-form-field
                    v-model="model.titles[locale]"
                    type="text"
                    name="title"
                    validation="required"
                />
            </div>
        </template>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    export default {
        name: 'CouponBasePanel',

        components: {
            YFormField,
        },

        props: {
            settings: Object,
            value   : Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                options: {
                    amount_fee    : this.$t('coupons.editor.amount.fee'),
                    amount_percent: this.$t('coupons.editor.amount.percent'),
                },
            };
        },

        computed: {
            /**
             * Return the locales array
             */
            locales() {
                return this.settings && this.settings.locales ? this.settings.locales : [];
            },
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            'model.amount': {
                /**
                 * Reset form after changing radio
                 */
                handler() {
                    this.model.amount_fee = '';
                    this.model.amount_percent = '';
                    this.model.amount_percent_max = '';
                },
            },

        },

        /**
         * @inheritdoc
         */
        created() {
            if (!this.model.titles) {
                this.model.titles = {};
            }
        },
    };

</script>
