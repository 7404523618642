<template>
    <div>
        <y-panel :title="$t('coupons.editor.rules.rules')">
            <template slot="functions">
                <div class="col-sm-12">
                    <y-form-field
                        v-model="search"
                        type="text"
                        name="search"
                        no-label
                        :placeholder="$t('fields.search')"
                    />
                </div>
            </template>

            <div class="row">
                <!-- Available Rules -->
                <div class="col-sm-6 part-title">
                    <h6 class="text">
                        {{ $t('coupons.editor.rules.available_rules') }}
                    </h6>
                    <hr>
                    <ul
                        v-if="Array.isArray(availableRules) && availableRules.length"
                        class="m0 p0"
                    >
                        <y-rule-item
                            v-for="rule in availableRules"
                            :key="rule.index"
                            :rule="rule"
                            @select="onAddRule"
                        />
                    </ul>
                    <y-rule-empty-item v-else />
                </div>

                <!-- Selected Rules -->
                <div class="col-sm-6 part-title">
                    <h6 class="text">
                        {{ $t('coupons.editor.rules.selected_rules') }}
                    </h6>
                    <hr>
                    <draggable
                        v-if="Array.isArray(model._rules_list) && model._rules_list.length"
                        tag="ul"
                        :disabled="model._rules_list.length <= 1"
                        class="m0 p0"
                        handle=".coupon-rule-item .md-menu"
                        :list="model._rules_list"
                        :animation="300"
                    >
                        <y-rule-item
                            v-for="rule in model._rules_list"
                            :key="rule.index"
                            :rule="rule"
                            editable
                            @edit="onEditSelectedRule"
                            @delete="onDeleteSelectedRule"
                        />
                    </draggable>
                    <y-rule-empty-item v-else />
                </div>
            </div>
        </y-panel>

        <y-rule-settings-modal
            ref="Modal"
            @save="saveRuleSettings"
        />
    </div>
</template>

<script>
    import Draggable from 'vuedraggable';
    import YFormField from '@deps/form/FormField';
    import YRuleItem from '@/modules/coupons/components/editor/RuleItem';
    import YRuleSettingsModal from '@/modules/coupons/components/editor/RuleSettingsModal';
    import YRuleEmptyItem from '@/modules/coupons/components/editor/RuleEmptyItem';

    export default {
        name: 'CouponRulesPanel',

        components: {
            Draggable,
            YFormField,
            YRuleItem,
            YRuleSettingsModal,
            YRuleEmptyItem,
        },

        props: {
            value: Object,
            rules: Array,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model : this.value || {},
                search: null,
            };
        },

        computed: {
            /**
             * return available rules according to the search value and previously selected rules
             */
            availableRules() {
                let available = this.rules;
                if (this.search) {
                    const searchLowerCase = this.search.toLowerCase();
                    available = available.filter((rule) => rule.title.toLowerCase().includes(searchLowerCase));
                }
                if (this.model._rules_list.length) {
                    available = available.filter((rule) => !this.model._rules_list.find((selectedRule) => selectedRule.index === rule.index));
                }

                return available;
            },
        },

        methods: {
            /**
             * Add rule to selected rules
             *
             * @param ruleIndex
             */
            onAddRule(ruleIndex) {
                const params = {
                    id      : this.model.id,
                    draft_id: this.model.draft_id,
                    index   : ruleIndex,
                    isEdit  : false,
                };
                this.$refs.Modal.open(params);
            },

            /**
             * add rule to the selected rules
             *
             * @param root0
             * @param root0.draftId
             * @param root0.index
             * @param root0.isEdit
             */
            saveRuleSettings({ draftId, index, isEdit }) {
                this.model.draft_id = draftId;
                if (!isEdit) {
                    this.model._rules_list.push(this.rules.find((rule) => rule.index === index));
                }
            },

            /**
             * edit previously selected rule
             *
             * @param ruleIndex
             */
            onEditSelectedRule(ruleIndex) {
                const params = {
                    id      : this.model.id,
                    draft_id: this.model.draft_id,
                    index   : ruleIndex,
                    isEdit  : true,
                };
                this.$refs.Modal.open(params);
            },

            /**
             * remove rule from selected rules
             *
             * @param ruleIndex
             */
            onDeleteSelectedRule(ruleIndex) {
                const params = {
                    index   : ruleIndex,
                    draft_id: this.model.draft_id,
                    id      : this.model.id,
                };
                this.$wait.start(`remove-rule-${ruleIndex}`);
                this.$services.Coupons.ruleRemove(params).then((response) => {
                    this.model.draft_id = response.data.metadata.draft_id;
                    this.model._rules_list = this.model._rules_list.filter((rule) => rule.index !== ruleIndex);
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end(`remove-rule-${ruleIndex}`);
                });
            },
        },
    };
</script>
