<template>
    <div>
        <y-page-head
            :title="title"
            icon="md-ticket-percent"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    v-if="isEdit"
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    form="main-form"
                    wide
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>

                <y-button
                    v-if="showBatchCodeLinks"
                    tag="router-link"
                    :to="makeBatchCodesLink()"
                >
                    {{ $t('coupons.batch.title') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main class="coupons-editor">
                <form
                    id="main-form"
                    @submit.prevent="submit(save)"
                >
                    <div class="row">
                        <div class="col-sm-8">
                            <y-base-panel
                                v-model="model"
                                :settings="settings"
                            />
                            <y-rules-panel
                                v-model="model"
                                :rules="rules"
                            />
                        </div>

                        <div class="col-sm-4">
                            <y-amount-panel v-model="model" />
                            <y-group-panel
                                v-model="model"
                                :roles="roles"
                            />
                            <y-limitation-panel v-model="model" />
                        </div>
                    </div>
                </form>
            </main>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YAmountPanel from '@/modules/coupons/components/editor/AmountPanel';
    import YBasePanel from '@/modules/coupons/components/editor/BasePanel';
    import YGroupPanel from '@/modules/coupons/components/editor/GroupPanel';
    import YLimitationPanel from '@/modules/coupons/components/editor/LimitationPanel';
    import YRulesPanel from '@/modules/coupons/components/editor/RulesPanel';

    export default {
        name: 'CouponEdit',

        components: {
            YAmountPanel,
            YBasePanel,
            YGroupPanel,
            YLimitationPanel,
            YRulesPanel,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('coupons', this.$t('breadcrumbs.coupons')),
                this.$bc(
                    (this.$route.params.id && !this.$route.query.duplicate)
                        ? this.$t('coupons.title.edit')
                        : this.$t('coupons.title.create'),
                ),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    users: {},
                },
                settings: null,
                roles   : [],
                rules   : [],
            };
        },

        computed: {
            /**
             * Title of the page
             */
            title() {
                return this.isEdit ? this.$t('coupons.title.edit') : this.$t('coupons.title.create');
            },

            /**
             * indicate whether its in clone mode
             */
            isClone() {
                return !!this.$route.query.duplicate;
            },

            /**
             * Check if it is in edit mode
             */
            isEdit() {
                return !!this.$route.params.id && !this.isClone;
            },

            /**
             * determine whether to show batch codes links or not
             */
            showBatchCodeLinks() {
                return this.isEdit && !this.isClone;
            },
        },

        /**
         * Set Initial Data
         */
        created() {
            if (!this.model.title) {
                this.model.title = '';
            }
            if (!this.model.applicable_amount) {
                this.model.applicable_amount = '';
            }
            if (!this.model.applicable_percent) {
                this.model.applicable_percent = '';
            }
            if (!this.model.users) {
                this.model.users = {};
            }
            if (!this.model.code_usage_limit) {
                this.model.code_usage_limit = null;
            }
        },

        methods: {
            /**
             * Fetch order settings
             */
            fetch() {
                const params = {
                    id       : this.$route.params.id,
                    duplicate: this.isClone ? 1 : 0,
                };

                return this.$services.Coupons.rulebookFetch(params).then((response) => {
                    const { roles, rules } = response.data.metadata;
                    this.$set(this, 'settings', response.data.metadata);
                    this.model = response.data.results;
                    if (this.isClone) {
                        this.model.title = '';
                    }
                    this.roles = roles;
                    this.rules = rules;
                });
            },

            /**
             * Save order
             */
            save() {
                if (this.isClone) {
                    delete this.model.id;
                }
                return this.$services.Coupons.rulebookSave(this.model).then(() => {
                    this.$toast.success(this.$t('coupons.editor.messages.success'));
                    this.$router.push({
                        name  : 'panel-grid',
                        params: {
                            class  : 'rulebooks',
                            module : 'Coupons',
                            part   : 'list',
                            version: '1',
                        },
                    });
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Create link object that redirect user to batch codes grid
             */
            makeBatchCodesLink() {
                return {
                    name  : 'panel-grid',
                    params: {
                        module : 'Coupons',
                        class  : 'code-batches',
                        part   : 'list',
                        version: 1,
                    },
                    query: {
                        rulebook: this.$route.params.id,
                    },
                };
            },
        },

    };

</script>
